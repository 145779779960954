@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');


.font-Lora {
    font-family: 'Lora', serif;
}


@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

.font-FjallaOne {
    font-family: 'Fjalla One', sans-serif;
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css')