@font-face {
  font-family: 'Recoleta';
  src:
    url('./Recoleta-Bold.woff2') format('woff2'),
    url('./Recoleta-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Recoleta';
  src:
    url('./Recoleta-Black.woff2') format('woff2'),
    url('./Recoleta-Black.woff') format('woff');
  font-weight: 900; /* Black */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Recoleta';
  src:
    url('./Recoleta-Medium.woff2') format('woff2'),
    url('./Recoleta-Medium.woff') format('woff');
  font-weight: 500; /* Medium */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Recoleta';
  src:
    url('./Recoleta-Regular.woff2') format('woff2'),
    url('./Recoleta-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Recoleta';
  src:
    url('./Recoleta-Light.woff2') format('woff2'),
    url('./Recoleta-Light.woff') format('woff');
  font-weight: 300; /* Light */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Recoleta';
  src:
    url('./Recoleta-Thin.woff2') format('woff2'),
    url('./Recoleta-Thin.woff') format('woff');
  font-weight: 100; /* Thin */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Recoleta';
  src:
    url('./Recoleta-SemiBold.woff2') format('woff2'),
    url('./Recoleta-SemiBold.woff') format('woff');
  font-weight: 600; /* SemiBold */
  font-style: normal;
  font-display: swap;
}
