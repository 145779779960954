@font-face {
  font-family: 'GeneralSans';
  src:
    url('./GeneralSans-Bold.woff2') format('woff2'),
    url('./GeneralSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GeneralSans';
  src:
    url('./GeneralSans-Italic.woff2') format('woff2'),
    url('./GeneralSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GeneralSans';
  src:
    url('./GeneralSans-Light.woff2') format('woff2'),
    url('./GeneralSans-Light.woff') format('woff');
  font-weight: 300; /* Light */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GeneralSans';
  src:
    url('./GeneralSans-Extralight.woff2') format('woff2'),
    url('./GeneralSans-Extralight.woff') format('woff');
  font-weight: 200; /* Extralight */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GeneralSans';
  src:
    url('./GeneralSans-Medium.woff2') format('woff2'),
    url('./GeneralSans-Medium.woff') format('woff');
  font-weight: 500; /* Medium */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GeneralSans';
  src:
    url('./GeneralSans-Regular.woff2') format('woff2'),
    url('./GeneralSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GeneralSans';
  src:
    url('./GeneralSans-Semibold.woff2') format('woff2'),
    url('./GeneralSans-Semibold.woff') format('woff');
  font-weight: 600; /* Semibold */
  font-style: normal;
  font-display: swap;
}
